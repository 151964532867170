/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateCourseInquiryRequestDto,
  InquiriesListResponseDto,
  InquiryControllerGetInquiriesForInstructorParams,
  InquiryControllerGetInquiriesForStudentParams,
  InquiryResponseDto,
  UpdateInquiryRequestDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 유저의 문의하기
 */
export const inquiryControllerCreateInquiry = (
  createCourseInquiryRequestDto: CreateCourseInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/user`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCourseInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerCreateInquiryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>,
    TError,
    { data: CreateCourseInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>,
  TError,
  { data: CreateCourseInquiryRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>,
    { data: CreateCourseInquiryRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return inquiryControllerCreateInquiry(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerCreateInquiryMutationResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>
>;
export type InquiryControllerCreateInquiryMutationBody =
  CreateCourseInquiryRequestDto;
export type InquiryControllerCreateInquiryMutationError = ErrorType<unknown>;

/**
 * @summary 유저의 문의하기
 */
export const useInquiryControllerCreateInquiry = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>,
    TError,
    { data: CreateCourseInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerCreateInquiry>>,
  TError,
  { data: CreateCourseInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerCreateInquiryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * <strong>기능 설명:</strong><br>
<dd> 강사 본인이 본인의 강의에 문의를 남김, 등록된 데이터는 /inquiry/user 와 동일 </dd><br>

<strong> 제약 조건</strong><br>
<ul>
  <li>강의 검증 - 존재하고, 공개된 강의인지 확인</li>
  <li>강사 검증 - 본인의 강좌 인지 확인 </li>
</ul>
 * @summary 강사의 문의하기 - 본인의 강의에 직접 문의 (강사 인증)
 */
export const inquiryControllerCreateInquiryByInstructor = (
  createCourseInquiryRequestDto: CreateCourseInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/instructor`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCourseInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerCreateInquiryByInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>,
    TError,
    { data: CreateCourseInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>,
  TError,
  { data: CreateCourseInquiryRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>,
    { data: CreateCourseInquiryRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return inquiryControllerCreateInquiryByInstructor(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerCreateInquiryByInstructorMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>
  >;
export type InquiryControllerCreateInquiryByInstructorMutationBody =
  CreateCourseInquiryRequestDto;
export type InquiryControllerCreateInquiryByInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사의 문의하기 - 본인의 강의에 직접 문의 (강사 인증)
 */
export const useInquiryControllerCreateInquiryByInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>,
    TError,
    { data: CreateCourseInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerCreateInquiryByInstructor>>,
  TError,
  { data: CreateCourseInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerCreateInquiryByInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 문의 리스트 조회 (root 문의일 경우, 하위 문의들 포함됨)
 * @summary 문의 리스트 조회 (강사)
 */
export const inquiryControllerGetInquiriesForInstructor = (
  params?: InquiryControllerGetInquiriesForInstructorParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InquiriesListResponseDto>(
    { url: `/inquiry/instructor`, method: "GET", params, signal },
    options,
  );
};

export const getInquiryControllerGetInquiriesForInstructorQueryKey = (
  params?: InquiryControllerGetInquiriesForInstructorParams,
) => {
  return [`/inquiry/instructor`, ...(params ? [params] : [])] as const;
};

export const getInquiryControllerGetInquiriesForInstructorQueryOptions = <
  TData = Awaited<
    ReturnType<typeof inquiryControllerGetInquiriesForInstructor>
  >,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: InquiryControllerGetInquiriesForInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiriesForInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInquiryControllerGetInquiriesForInstructorQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inquiryControllerGetInquiriesForInstructor>>
  > = ({ signal }) =>
    inquiryControllerGetInquiriesForInstructor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof inquiryControllerGetInquiriesForInstructor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InquiryControllerGetInquiriesForInstructorQueryResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerGetInquiriesForInstructor>>
>;
export type InquiryControllerGetInquiriesForInstructorQueryError =
  ErrorType<InquiriesListResponseDto>;

/**
 * @summary 문의 리스트 조회 (강사)
 */
export const useInquiryControllerGetInquiriesForInstructor = <
  TData = Awaited<
    ReturnType<typeof inquiryControllerGetInquiriesForInstructor>
  >,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: InquiryControllerGetInquiriesForInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiriesForInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getInquiryControllerGetInquiriesForInstructorQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강사가 문의에 답변하기
 */
export const inquiryControllerCreateInquiryResponseByInstructor = (
  rootInquiryId: number,
  createCourseInquiryRequestDto: CreateCourseInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/${rootInquiryId}/instructor`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCourseInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerCreateInquiryResponseByInstructorMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
      >,
      TError,
      { rootInquiryId: number; data: CreateCourseInquiryRequestDto },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
    >,
    TError,
    { rootInquiryId: number; data: CreateCourseInquiryRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
      >,
      { rootInquiryId: number; data: CreateCourseInquiryRequestDto }
    > = (props) => {
      const { rootInquiryId, data } = props ?? {};

      return inquiryControllerCreateInquiryResponseByInstructor(
        rootInquiryId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type InquiryControllerCreateInquiryResponseByInstructorMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
    >
  >;
export type InquiryControllerCreateInquiryResponseByInstructorMutationBody =
  CreateCourseInquiryRequestDto;
export type InquiryControllerCreateInquiryResponseByInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사가 문의에 답변하기
 */
export const useInquiryControllerCreateInquiryResponseByInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
    >,
    TError,
    { rootInquiryId: number; data: CreateCourseInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof inquiryControllerCreateInquiryResponseByInstructor>
  >,
  TError,
  { rootInquiryId: number; data: CreateCourseInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerCreateInquiryResponseByInstructorMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * @summary 유저가 문의 수정하기
 */
export const inquiryControllerUpdateInquiryByUser = (
  inquiryId: number,
  updateInquiryRequestDto: UpdateInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/${inquiryId}/user`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerUpdateInquiryByUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>,
    TError,
    { inquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>,
  TError,
  { inquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>,
    { inquiryId: number; data: UpdateInquiryRequestDto }
  > = (props) => {
    const { inquiryId, data } = props ?? {};

    return inquiryControllerUpdateInquiryByUser(
      inquiryId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerUpdateInquiryByUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>
>;
export type InquiryControllerUpdateInquiryByUserMutationBody =
  UpdateInquiryRequestDto;
export type InquiryControllerUpdateInquiryByUserMutationError =
  ErrorType<unknown>;

/**
 * @summary 유저가 문의 수정하기
 */
export const useInquiryControllerUpdateInquiryByUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>,
    TError,
    { inquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByUser>>,
  TError,
  { inquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerUpdateInquiryByUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 유저가 문의 삭제하기
 */
export const inquiryControllerDeleteInquiryByUser = (
  inquiryId: number,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    { url: `/inquiry/${inquiryId}/user`, method: "DELETE" },
    options,
  );
};

export const getInquiryControllerDeleteInquiryByUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>,
    TError,
    { inquiryId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>,
  TError,
  { inquiryId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>,
    { inquiryId: number }
  > = (props) => {
    const { inquiryId } = props ?? {};

    return inquiryControllerDeleteInquiryByUser(inquiryId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerDeleteInquiryByUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>
>;

export type InquiryControllerDeleteInquiryByUserMutationError =
  ErrorType<unknown>;

/**
 * @summary 유저가 문의 삭제하기
 */
export const useInquiryControllerDeleteInquiryByUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>,
    TError,
    { inquiryId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerDeleteInquiryByUser>>,
  TError,
  { inquiryId: number },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerDeleteInquiryByUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강사가 문의 답변 수정하기
 */
export const inquiryControllerUpdateInquiryByInstructor = (
  inquiryId: number,
  updateInquiryRequestDto: UpdateInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/${inquiryId}/instructor`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerUpdateInquiryByInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>,
    TError,
    { inquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>,
  TError,
  { inquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>,
    { inquiryId: number; data: UpdateInquiryRequestDto }
  > = (props) => {
    const { inquiryId, data } = props ?? {};

    return inquiryControllerUpdateInquiryByInstructor(
      inquiryId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerUpdateInquiryByInstructorMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>
  >;
export type InquiryControllerUpdateInquiryByInstructorMutationBody =
  UpdateInquiryRequestDto;
export type InquiryControllerUpdateInquiryByInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사가 문의 답변 수정하기
 */
export const useInquiryControllerUpdateInquiryByInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>,
    TError,
    { inquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerUpdateInquiryByInstructor>>,
  TError,
  { inquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerUpdateInquiryByInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강사가 문의 답변 삭제하기
 */
export const inquiryControllerDeleteReplyByInstructor = (
  replyInquiryId: number,
  updateInquiryRequestDto: UpdateInquiryRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<void>(
    {
      url: `/inquiry/${replyInquiryId}/instructor`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: updateInquiryRequestDto,
    },
    options,
  );
};

export const getInquiryControllerDeleteReplyByInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>,
    TError,
    { replyInquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>,
  TError,
  { replyInquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>,
    { replyInquiryId: number; data: UpdateInquiryRequestDto }
  > = (props) => {
    const { replyInquiryId, data } = props ?? {};

    return inquiryControllerDeleteReplyByInstructor(
      replyInquiryId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type InquiryControllerDeleteReplyByInstructorMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>
  >;
export type InquiryControllerDeleteReplyByInstructorMutationBody =
  UpdateInquiryRequestDto;
export type InquiryControllerDeleteReplyByInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사가 문의 답변 삭제하기
 */
export const useInquiryControllerDeleteReplyByInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>,
    TError,
    { replyInquiryId: number; data: UpdateInquiryRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inquiryControllerDeleteReplyByInstructor>>,
  TError,
  { replyInquiryId: number; data: UpdateInquiryRequestDto },
  TContext
> => {
  const mutationOptions =
    getInquiryControllerDeleteReplyByInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 문의 리스트 조회 (root 문의일 경우, 하위 문의들 포함됨)
 * @summary 문의 리스트 조회 (수강생)
 */
export const inquiryControllerGetInquiriesForStudent = (
  params?: InquiryControllerGetInquiriesForStudentParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InquiriesListResponseDto>(
    { url: `/inquiry/student`, method: "GET", params, signal },
    options,
  );
};

export const getInquiryControllerGetInquiriesForStudentQueryKey = (
  params?: InquiryControllerGetInquiriesForStudentParams,
) => {
  return [`/inquiry/student`, ...(params ? [params] : [])] as const;
};

export const getInquiryControllerGetInquiriesForStudentQueryOptions = <
  TData = Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: InquiryControllerGetInquiriesForStudentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInquiryControllerGetInquiriesForStudentQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>
  > = ({ signal }) =>
    inquiryControllerGetInquiriesForStudent(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InquiryControllerGetInquiriesForStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>
>;
export type InquiryControllerGetInquiriesForStudentQueryError =
  ErrorType<InquiriesListResponseDto>;

/**
 * @summary 문의 리스트 조회 (수강생)
 */
export const useInquiryControllerGetInquiriesForStudent = <
  TData = Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>,
  TError = ErrorType<InquiriesListResponseDto>,
>(
  params?: InquiryControllerGetInquiriesForStudentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiriesForStudent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInquiryControllerGetInquiriesForStudentQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 문의 1건 조회 (root 문의일 경우, 하위 문의들 포함됨)
 * @summary 문의 1건 조회
 */
export const inquiryControllerGetInquiryById = (
  inquiryId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InquiryResponseDto>(
    { url: `/inquiry/${inquiryId}`, method: "GET", signal },
    options,
  );
};

export const getInquiryControllerGetInquiryByIdQueryKey = (
  inquiryId: number,
) => {
  return [`/inquiry/${inquiryId}`] as const;
};

export const getInquiryControllerGetInquiryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>,
  TError = ErrorType<InquiryResponseDto>,
>(
  inquiryId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInquiryControllerGetInquiryByIdQueryKey(inquiryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>
  > = ({ signal }) =>
    inquiryControllerGetInquiryById(inquiryId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!inquiryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InquiryControllerGetInquiryByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>
>;
export type InquiryControllerGetInquiryByIdQueryError =
  ErrorType<InquiryResponseDto>;

/**
 * @summary 문의 1건 조회
 */
export const useInquiryControllerGetInquiryById = <
  TData = Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>,
  TError = ErrorType<InquiryResponseDto>,
>(
  inquiryId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inquiryControllerGetInquiryById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInquiryControllerGetInquiryByIdQueryOptions(
    inquiryId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
