export const getInstructorName = (
  course: any,
  locale: "ko" | "en",
): string | undefined => {
  if (course?.academy && course.academy.language === locale) {
    return course.academy.name;
  }

  return course?.instructor?.find((inst: any) => inst.language === locale)
    ?.artistName;
};
